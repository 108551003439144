<template>
  <div class="content">
    <div class="top">
      <!--   选择大币种   -->
      <a-select
          size="small"
          @search="searchBigCoinListData"
          v-model="params.coinSid"
          :filter-option="untils.filterOption"
          showSearch
          @change="changeVersionlistSearch"
          placeholder="请选择大币种"
          allowClear
          style="width:150px;margin:5px;"
      >
        <a-select-option
            v-for="item of bigCoinListData"
            :key="item.id"
            :value="item.sid"
        >{{ item.coinKindName }}</a-select-option>
      </a-select>
      <!-- 选择版别 -->
      <a-select
        :dropdownMatchSelectWidth="false"
        @select="search"
        allowClear
        showSearch
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 180px"
        v-model="params.versionSid"
        :dropdownMenuStyle="{'max-height': '500px'}"
        placeholder="版别"
      >
        <a-select-option
          v-for="item of versionList"
          :key="item.versionSid"
          :value="item.versionSid"
        >{{ `${item.versionName || ""}-${item.confirmVersionName}-(${item.size || 0})` }}</a-select-option>
      </a-select>
      <!-- 选择授权用户 -->
      <a-select
          v-if="false"
        :dropdownMatchSelectWidth="false"
        @select="search"
        allowClear
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 180px"
        v-model="params.userId"
        placeholder="授权用户"
      >
        <a-select-option
          v-for="item of authUserList"
          :key="item.userId"
          :value="item.userId"
        >{{ item.userName}}</a-select-option>
      </a-select>
      <a-input
        placeholder="小币种|版别|ID"
        allowClear
        v-model="params.title"
        @pressEnter="search"
        @change="search"
        size="small"
        style="margin: 5px; width: 180px"
      />
      <a-input
          placeholder="第三方ID"
          allowClear
          v-model="params.thirdAuthId"
          @pressEnter="search"
          @change="search"
          size="small"
          style="margin: 5px; width: 180px"
      />

      <a-select
        allowClear
        v-model="params.answerType"
        size='small'
        style="width:120px;margin:5px;"
        placeholder='作答类型'
      >
        <a-select-option :value='0'>正常标记</a-select-option>
        <a-select-option :value='3'>手动标记</a-select-option>
        <a-select-option :value='4'>假货</a-select-option>
        <a-select-option :value='1'>跳过</a-select-option>
        <a-select-option :value='2'>图片不清晰</a-select-option>
      </a-select>
      <!--  -->
      <a-select
        allowClear
        v-model="params.companyType"
        size='small'
        style="width:120px;margin:5px;"
        placeholder='数据来源'
      >
        <a-select-option
          v-for="(item, index) in companyTypeList"
          :value="item.value"
          :key="index"
        >{{item.name}}</a-select-option>
      </a-select>
      <!-- 是否延迟 -->
      <a-select
        allowClear
        v-model="params.delayStatus"
        size='small'
        style="width:120px;margin:5px;"
        placeholder='是否延迟'
      >
        <a-select-option :value='0'>否</a-select-option>
        <a-select-option :value='1'>是</a-select-option>
      </a-select>
      <!-- 是否推送至盲审 -->
      <a-select
        allowClear
        v-model="params.blindTrialStatus"
        size='small'
        style="width:160px;margin:5px;"
        placeholder='是否推送至盲审'
      >
        <a-select-option :value='0'>未推送至盲审</a-select-option>
        <a-select-option :value='1'>已推送至盲审</a-select-option>
      </a-select>
      <!-- 是否废弃 -->
      <a-select
        allowClear
        v-model="params.abandonmentStatus"
        size='small'
        style="width:160px;margin:5px;"
        placeholder='是否废弃'
      >
        <a-select-option :value='0'>未废弃</a-select-option>
        <a-select-option :value='1'>已废弃</a-select-option>
      </a-select>
      <a-checkbox-group v-model="isAlike" @change="handleChangeCheckBox">
        <a-checkbox :value="1">答案一致</a-checkbox>
      </a-checkbox-group>
      <a-button
          type="primary" size="small"
          style="margin-right: 10px"
        @click="search"
      >搜索</a-button>

      <a-popconfirm
        title="确定加入盲审？"
        ok-text="确认"
        cancel-text="取消"
        @confirm="insertConfrim()"
        :disabled="isDisabled"
      >
        <a-button
          :disabled="isDisabled"
          size="small"
          style="margin-left: 5px"
        >加入盲审</a-button>
      </a-popconfirm>

      <a-popconfirm
          title="确定批量确认？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="handleBatchConfirm()"
          :disabled="isDisabled"
      >
        <a-button
            :disabled="isDisabled"
            size="small"
            style="margin-left: 5px"
        >批量确认</a-button>
      </a-popconfirm>

    </div>
    <div class="table-content">
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="list"
        :data-source="data"
        :rowKey="(record, index) => index"
        @change="changePage"
        :pagination="pagination"
      >
        <!-- id -->
        <div
          slot="Id"
          slot-scope="row"
        >
          {{`${row.ratingSpliderId}(${row.thirdAuthId || ""}-${row.dikuConfirmVersionName})` }}
        </div>
        <!-- 版别 -->
        <div
          slot="itemVersionName"
          slot-scope="row"
        >
          <div>{{row.itemVersionName}}({{row.itemCompanyType | cmpanyName}})</div>
          <div
            class="name-lable"
            v-if="row.itemVersionAliasName"
          >{{row.itemVersionAliasName || ""}}</div>
        </div>
        <!-- 正面图 -->
        <div
          slot="images"
          slot-scope="row"
          style="width:50px;height:50px;display:flex;justify-content:center;align-items:center;"
        >
          <img
            @click="previewImg(row)"
            style='max-width:50px;max-height:50px;'
            :src='row.showImageUrl'
          />
        </div>
        <div
          slot="answers"
          slot-scope="row"
        >
          <ul class="answers-header">
            <li>标注时间</li>
            <li v-if="false">用户名</li>
            <li>类型</li>
            <li>答案</li>
            <li>操作</li>
          </ul>
          <ul
            class="answers-list"
            v-for="(item, index) in row.answers"
            :key="index"
            :class="{
              red: item.addressType == 1,
              yellow: item.addressType == 2,
            }"
          >
            <li>{{item.createTime}}</li>
            <li v-if="false">{{item.userName}}</li>
            <li>{{item.answerType | answerTypeFilter}}</li>
            <li>{{item.answerVersionName || ''}}</li>
            <li
              class="action-box"
              v-if="(item.answerType == 0 || item.answerType == 3 || item.answerType == 5) && !row.confirmVersionName"
            >
              <div
                class="answers-btn"
                v-if="item.answerType == 3 || item.answerType == 5"
                @click="customClick(row.ratingSpliderId)"
              >确认</div>
              <a-popconfirm
                v-else
                title="确认版别名称么？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="confirmVersion(row.ratingSpliderId, item.coinKindItemVersionSid)"
              >
                <div class="answers-btn">确认</div>
              </a-popconfirm>
              <a-popconfirm
                title="确认延迟确认么？"
                ok-text="确认"
                cancel-text="取消"
                @confirm="delayConfirmVersion(row.ratingSpliderId, item.coinKindItemVersionSid)"
                v-if="item.delayStatus != 1"
              >
                <div class="answers-btn">延迟确认</div>
              </a-popconfirm>

            </li>
          </ul>
        </div>
        <a
          slot="name"
          slot-scope="text"
        >{{ text }}</a>
        <div
          slot="setup"
          slot-scope="row"
        >

          <a-button
              style="margin-top: 5px"
            type="primary" size="small"
            @click="taPianViewPicture(row)"
          >拓片比较</a-button>

          <a-button
              style="margin-top: 5px"
            v-if="row.confirmVersionName == null || row.confirmVersionName.length == 0"
            type="primary" size="small"
            @click="customClick(row.ratingSpliderId)"
          >自定义确认</a-button>
          <div>
            <a-button
                style="margin-top: 5px"
                type="primary" size="small"
                @click="viewPicture(row)"
            >原图对版</a-button>
          </div>
          <a-popconfirm
            title="确定要裁剪此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="cutConfrim(row.ratingSpliderId)"
          >
            <a-button style="margin-top: 5px" type="primary" size="small">裁剪</a-button>
          </a-popconfirm>
          <a-popconfirm
            title="确定要操作此项么？"
            ok-text="确认"
            cancel-text="取消"
            @confirm="remove(row.ratingSpliderId)"
          >
            <a-button
                style="margin-top: 5px"
              v-if="row.confirmVersionName == null || row.confirmVersionName.length == 0"
              type="danger" size="small"
            >废弃</a-button>
          </a-popconfirm>
        </div>
      </a-table>
    </div>

    <a-modal
      v-model="customModal"
      title="自定义确认"
      ok-text="确认"
      cancel-text="取消"
      @cancel="cancelCustomModal"
      @ok="confirmCustomModal"
    >
      <!-- 选择确认版别 -->
      <a-select
        showSearch
        allowClear
        :dropdownMatchSelectWidth="false"
        @search="getConfirmVersionList"
        :filter-option="untils.filterOption"
        size="small"
        style="margin: 5px; width: 280px"
        v-model="coinKindItemVersionSid"
        placeholder="请选择版别"
      >
        <a-select-option
          v-for="item of confirmVersionList"
          :key="item.sid"
          :value="item.sid"
        >{{ `${item.coinKindVersionName || ""}-${item.coinKindItemName || ""}`  }}</a-select-option>
      </a-select>
    </a-modal>
    <!-- 对版 -->
    <ViewPicture ref="showPicture"></ViewPicture>

    <TaPianViewPicture ref="taPianShowPicture"></TaPianViewPicture>
    
  </div>
</template>
<script>
import { companyTypeList } from "@/views/cmsPage/versionManage/ratingsplider/data.js";
import ViewPicture from "@/views/cmsPage/versionManage/ratingsplider/ViewPicture.vue";
import TaPianViewPicture from "@/views/cmsPage/versionManage/ratingsplider/TaPianViewPicture.vue";
import untils from "@/untils";
export default {
  components: {
    ViewPicture,
    TaPianViewPicture,
  },
  data() {
    return {
      untils,
      bigCoinListData: [],
      isAlike: undefined,
      companyTypeList: companyTypeList,
      list: [
        { title: "Id", align: "center",scopedSlots: { customRender: "Id" }, },
        { title: "大币种", align: "center", dataIndex: "itemCoinName" },
        { title: "小币种", align: "center", dataIndex: "itemCoinItemName" },
        {
          title: "评级-版别",
          align: "center",
          scopedSlots: { customRender: "itemVersionName" },
        },

        {
          title: "正反图",
          align: "center",
          scopedSlots: { customRender: "images" },
        },
        {
          title: "是否推送至盲审",
          align: "center",
          customRender: (text, row, index) => {
            switch (row.blindTrialStatus) {
              case 0:
                return "未推送";
              case 1:
                return "已推送";
              default:
                return "未推送";
            }
          },
        },

        {
          title: "作答列表",
          align: "center",
          scopedSlots: { customRender: "answers" },
        },
        {
          title: "确认-版别",
          align: "center",
          dataIndex: "confirmVersionName",
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "setup" },
        },
      ],
      data: [],
      pagination: {
        pageSize: 10,
        total: 0,
        current: 1,
        showTotal: (total) => `共${total}条`,
      },
      params: { pageNum: 1, pageSize: 10, title: "", confirmStatus: 0 },
      ratingSpliderId: "", //自定义确认时使用
      customModal: false,
      customValue: "",
      bigCoinList: [],
      versionList: [],
      authUserList: [],
      confirmVersionList: [],
      coinKindItemVersionSid: undefined,
      selectedRowKeys: [],
      selectedArr: [],
    };
  },
  computed: {
    isDisabled() {
      return this.selectedRowKeys.length <= 0;
    },
  },
  filters: {
    answerTypeFilter(val) {
      switch (val) {
        case 0:
          return "正常标记";
        case 1:
          return "跳过";
        case 2:
          return "不清晰";
        case 3:
          return "手动标记";
        case 4:
          return "假货";
        case 5:
          return "盲审";
        default:
          return "";
      }
    },
    cmpanyName(val) {
      switch (val) {
        case 1:
          return "GB";
        case 2:
          return "BC";
        case 10:
          return "QH";
        default:
          return "-";
      }
    },
  },
  async mounted() {
    await this.getList(this.params);
    await this.getBigCoin();
  },
  methods: {
    /** 搜索大币种 */
    searchBigCoinListData(inner) {
      this.axios("/dq_admin/kind/list", {
        params: { coinName: inner },
      }).then((res) => {
        if (res.status == "200") {
          const { records } = res.data;
          this.bigCoinListData = records;
        }
      });
    },
    /** 批量确认 */
    async handleBatchConfirm() {
      const arr = this.selectedArr.map((el) => {
        return el.ratingSpliderId;
      });
      this.$loading.show();
      const res = await this.axios(
          "/dq_admin/ratingSpliderUserAnswer/batchConfirmation",
          {
            params: {
              ratingSpliderIds: arr.join(","),
            },
          }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.getList(this.params);
    },
    /** 相同币种刷选 */
    handleChangeCheckBox(e) {
      if (e.length < 1) {
        this.isAlike = undefined
        delete this.params.answerAgreement
      }
    },
    async insertConfrim() {
      const arr = this.selectedArr.map((el) => {
        return el.ratingSpliderId;
      });
      this.$loading.show();
      const res = await this.axios(
        "/dq_admin/ratingSplider/pushToBlindReview",
        {
          params: {
            ratingRpliderIds: arr.join(","),
          },
        }
      );
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.getList(this.params);
    },
    // 选中
    onSelectChange(keys, arr) {
      this.selectedRowKeys = keys;
      this.selectedArr = arr;
    },
    // 裁剪图片
    async cutConfrim(id) {
      this.$loading.show();
      const res = await this.axios("/dq_admin/ratingSplider/editCutImage", {
        params: {
          ratingSpliderId: id,
        },
      });
      this.$loading.hide();
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.getList(this.params);
    },
    // 查看图片
    viewPicture(row) {
      this.$refs["showPicture"].show(row);
    },

    taPianViewPicture(row){
      this.$refs["taPianShowPicture"].show(row);
    },


    // 延迟确认
    async delayConfirmVersion(id) {
      const res = await this.axios(
        "/dq_admin/ratingSpliderUserAnswer/delayConfirm",
        {
          params: {
            id: id,
          },
        }
      );
      if (res.status != 200) return;
      this.$message.success(res.message);
      this.getList(this.params);
    },
    // 获取授权用户
    async getAuthUser() {
      const res = await this.axios(
        "/dq_admin/ratingSpliderUserAnswer/listAuthUsersByCoin",
        {
          params: {
            coinSid: this.params.coinSid,
          },
        }
      );
      if (res.status != 200) return;
      this.authUserList = res.data;
    },
    // 获取大币种
    async getBigCoin() {
      const res = await this.axios(
        "/dq_admin/ratingSpliderUserAnswer/listAnswerCoin"
      );
      if (res.status != 200) return;
      this.bigCoinList = res.data;
    },
    // 选择大币种
    changeVersionlistSearch(val) {
      delete this.params.versionSid;
      delete this.params.userId;
      if (!val) {
        this.versionList = [];
        this.authUserList = [];
        return;
      }
      this.getVersion();
      // this.getAuthUser();
    },
    // 获取版别
    async getVersion() {
      const res = await this.axios(
        "/dq_admin/ratingSpliderUserAnswer/listAnswerVersionsByCoin",
        {
          params: {
            coinSid: this.params.coinSid,
            confirmStatus: this.params.confirmStatus,
          },
        }
      );
      if (res.status != 200) return;
      this.versionList = res.data;
    },

    // 确认
    confirmCustomModal() {
      this.confirmVersion(this.ratingSpliderId, this.coinKindItemVersionSid);
      this.customValue = "";
      this.customModal = false;
    },
    // 取消
    cancelCustomModal() {
      this.customValue = "";
    },
    // 自定义确认点击
    async customClick(id) {
      this.ratingSpliderId = id;
      this.customModal = true;
      // 获取版别
      this.getConfirmVersionList();
    },
    // 获取自定义确认版别列表
    async getConfirmVersionList(keyword) {
      const res = await this.axios(
        "/dq_admin/ratingSpliderUserAnswer/searchVersions",
        {
          params: {
            id: this.ratingSpliderId,
            keyword: keyword,
          },
        }
      );
      if (res.status != 200) return;
      this.confirmVersionList = res.data;
    },
    getList(params) {
      if (this.isAlike == 1) {
        params.answerAgreement = 1
      }
      this.axios("/dq_admin/ratingSpliderUserAnswer/list", {
        params: params,
      }).then((res) => {
        let list = res.data.records;
        this.data = list;
        this.pagination.total = res.data.total;
        this.selectedRowKeys = [];
      });
    },

    changePage(page) {
      const { current, pageSize } = page;
      this.pagination.current = current;
      this.params.pageNum = current;
      this.params.pageSize = pageSize;
      this.getList(this.params);
    },

    previewImg(row) {
      const list = [];
      if (row.showImageUrl) {
        list.push({
          img_url: row.showImageUrl,
        });
      }
      if (row.revealImageUrl) {
        list.push({
          img_url: row.revealImageUrl,
        });
      }
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },

    search(e) {
      this.params.page = 1;
      this.params.pageNum = 1;
      this.pagination.current = 1;
      this.getList(this.params);
    },

    valueNull() {
      this.getList(this.params);
      this.pagination.current = 1;
    },

    remove(id) {
      this.axios("/dq_admin/ratingSpliderUserAnswer/delById", {
        params: { id: id },
      }).then((res) => {
        this.getList(this.params);
        this.$message.success("删除成功");
      });
    },
    confirmVersion(id, sid) {
      this.axios("/dq_admin/ratingSpliderUserAnswer/confirmVersion", {
        params: { id: id, coinKindItemVersionSid: sid },
      }).then((res) => {
        this.getList(this.params);
        this.$message.success("确认成功");
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  .search {
    width: 65px;
    height: 28px;
    border: none;
    background: #1890ff;
    color: #ffffff;
    border-radius: 5px;
    margin-right: 10px;
    outline-style: none;
    cursor: pointer;
  }
}
.modal {
  input {
    margin-bottom: 5px;
  }
}

.answers-header,
.answers-list {
  display: flex;
  list-style: none;
  &.red {
    background: rgb(230, 124, 124);
  }
  &.yellow {
    background: #e1b879;
  }
  li {
    width: 120px;
  }
  .answers-btn {
    color: #40a9ff;
    cursor: pointer;
    margin-right: 30px;
    &:nth-last-of-type(1) {
      margin-right: 0;
    }
    &.grey {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
.answers-header {
  background: #ffffff;
}

.name-lable {
  padding: 2px 8px;
  background: green;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin-top: 6px;
}

.action-box {
  display: flex;
  justify-content: center;
}
</style>
