var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-modal",
        {
          attrs: {
            bodyStyle: {
              padding: "10px"
            },
            width: 1400
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c("div", { staticClass: "footer" }, [
                    _c(
                      "div",
                      { staticClass: "footer-btn" },
                      [
                        _c(
                          "a-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.cancel()
                              }
                            }
                          },
                          [_vm._v("关闭")]
                        )
                      ],
                      1
                    )
                  ])
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "con-body" }, [
              _c(
                "div",
                { staticClass: "c-right" },
                _vm._l(_vm.showImages, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "image-view",
                      class: {
                        bg: item.type == 1
                      }
                    },
                    [
                      _c("div", { staticClass: "c-info" }, [
                        _c("img", {
                          attrs: { src: _vm.versionImage },
                          on: { click: _vm.previewImg }
                        }),
                        _c("div", { staticClass: "item-info" })
                      ]),
                      _vm._l(item.list, function(item2, index2) {
                        return _c(
                          "div",
                          { key: index2, staticClass: "image-item" },
                          [
                            _c("div", { staticClass: "image-box" }, [
                              _c("img", {
                                staticClass: "image",
                                attrs: { src: item2.url }
                              }),
                              _c("div", { staticClass: "image-box-fixed" }, [
                                _c("div", [_vm._v(_vm._s(item2.id))]),
                                _c("div", [_vm._v(_vm._s(item2.distance))])
                              ])
                            ]),
                            _c("div", { staticClass: "item-info" }, [
                              _c("div", [_vm._v(_vm._s(item2.versionName))]),
                              _vm.pageType != 1
                                ? _c("div", [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("tagNameFilter")(item2.tagNames)
                                      )
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]
                        )
                      }),
                      _vm.pageType == 1
                        ? _c(
                            "div",
                            { staticClass: "item-btn" },
                            [
                              _c(
                                "a-button",
                                {
                                  staticClass: "setup-btn",
                                  attrs: { size: "small", type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.pushStore(item)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.type == 1 ? "确认版别" : "推送到底库"
                                    )
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ])
        ]
      ),
      _c("ModalPushStore", { ref: "push", on: { success: _vm.success } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }