<template>
  <div>
    <a-modal
      v-model="showModal"
      :bodyStyle='{
        padding: "10px",
      }'
      :width='1400'
    >
      <div class="content">
        <div class="con-body">
          <div class="c-right">
            <div
              class="image-view"
              v-for="(item, index) in showImages"
              :class="{
                bg: item.type == 1
              }"
              :key="index"
            >
              <div class="c-info">
                <img :src="versionImage" @click="previewImg" />
                <div class="item-info">
                  <!-- <div>{{recordList[index].itemName + " " + recordList[index].versionName}}</div> -->
                </div>
              </div>
              <div
                class="image-item"
                v-for="(item2, index2) in item.list"
                :key="index2"
              >
                <div class="image-box">
                  <img
                    class="image"
                    :src="item2.url"
                  />
                  <div class="image-box-fixed">
                    <div>{{item2.id}}</div>
                    <div>{{item2.distance}}</div>
                  </div>
                </div>

                <div class="item-info">
                  <div>{{item2.versionName}}</div>
                  <div v-if="pageType != 1">{{item2.tagNames | tagNameFilter}}</div>
                </div>

              </div>
              <div
                class="item-btn"
                v-if="pageType == 1"
              >
                <a-button
                  size="small"
                  type="primary"
                  class="setup-btn"
                  @click="pushStore(item)"
                >{{item.type == 1 ? "确认版别" : "推送到底库"}}</a-button>
              </div>
            </div>
          </div>

        </div>

      </div>

      <template #footer>
        
        <div class="footer">

          <!--
          <div>
            <a-radio-group
              v-model="imageType"
              :default-value="0"
              button-style="solid"
            >
              <a-radio-button :value="0">
                正面图
              </a-radio-button>
              <a-radio-button :value="1">
                背面图
              </a-radio-button>
            </a-radio-group>

          </div>
          -->

          <div class="footer-btn">
            <a-button @click="cancel()">关闭</a-button>
          </div>
        </div>

      </template>
    </a-modal>
    <!-- 推送到底库 -->
    <ModalPushStore
      ref="push"
      @success="success"
    ></ModalPushStore>
  </div>

</template>

<script>
import ModalPushStore from "@/views/cmsPage/versionManage/duibanrecord/ModalPushStore.vue";
export default {
  data() {
    return {
      showModal: false,
      imageType: 0,
      curItem: {},
      originalInfo: {},
      recordList: [],
      versionId: "",
      pageType: 0,
      coinItemList: [],
      versionList: [],
    };
  },
  computed: {
    versionImage() {
      const { versionFrontImage, versionBackImage } = this.originalInfo;
      return this.imageType == 0 ? versionFrontImage : versionBackImage;
    },
    showImages() {
      return this.recordList.map((el) => {
        const list = el.list.map((el2) => {
          return {
            ...el2,
            url:
              this.imageType == 0
                ? el2.versionFrontImage
                : el2.versionBackImage,
          };
        });
        return {
          ...el,
          list,
        };
      });
    },
  },
  filters: {
    tagNameFilter(val) {
      // return val.replaceAll(",", " | ");
    },
  },
  components: { ModalPushStore },
  methods: {
    show(data, type, versionId) {
      this.pageType = type;
      this.curItem = JSON.parse(JSON.stringify(data));
      this.versionId = versionId || "";
      this.imageType = 0;
      this.getInfo();
    },
    pushStore(row) {
      this.$refs["push"].show({
        ...row,
      });
    },
    // 预览图片
    previewImg(){
      const list = [{
        img_url: this.versionImage
      }];
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
      });
    },
    success() {
      this.getInfo();
    },
    // 默认值
    async getInfo() {
      this.$loading.show();
      const res = await this.axios("/dq_admin/ratingSpliderUserAnswer/getRubbingAlignment", {
        params: {
          ratingSpliderId: this.curItem.ratingSpliderId,
          // versionId: this.versionId,
          // type: this.pageType,
        },
      });
      this.$loading.hide();
      if (res.status != 200) return;
      const { originalInfo, recordList } = res.data;
      this.originalInfo = JSON.parse(JSON.stringify(originalInfo));
      this.recordList = JSON.parse(JSON.stringify(recordList));
      this.images = res.data;
      this.showModal = true;
    },
    
    // 关闭
    cancel() {
      this.showModal = false;
      this.originalInfo = {};
      this.recordList = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.con-body {
  max-height: 500px;
  overflow-y: scroll;
  display: flex;
  padding-top: 10px;
}
.c-info {
  margin-right: 10px;
  margin-bottom: 10px;
  height: 100%;
  img {
    width: 200px;
    height: 200px;
  }
}
.image-view {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  &.bg {
    background: #e6f7ff;
  }
  .image-item {
    width: 200px;
    margin-right: 10px;
  }
  .image-box {
    width: 200px;
    text-align: center;
    position: relative;
    .image {
      height: 200px;
    }
    .image-box-fixed {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background: rgba(0, 0, 0, 0.3);
      color: #fff;
      font-weight: bold;
      padding: 0 6px;
    }
  }
  .item-info {
    border-radius: 4px;
    padding: 0 5px;
    text-align: center;
    & > div {
      margin-top: 2px;
    }
  }
}
.item-btn {
  display: flex;
  align-items: center;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

::v-deep .ant-modal {
  top: 50%;
  transform: translateY(-50%);
}
</style>